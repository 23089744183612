import React from 'react';
import { withRouter, Link } from 'react-router';
import { checkPermission } from '../utils_v2/'
const TechSpecsNav = ({params, location}) => {
    // console.log('location',location);
    return(
        <div className='tech-specs-nav'>
            <div className='tech-nav-link'>
                <Link activeClassName='active' to={`/technical-specification/${params.type}/${params.aircraft_slug}/basic-details${location?.search.includes('archived-assets') ? '?archived-assets' : ''}`}>Basic Details</Link>
            </div>
            <div className='tech-nav-link'>
                <Link activeClassName='active' to={`/technical-specification/${params.type}/${params.aircraft_slug}/utilization${location?.search.includes('archived-assets') ? '?archived-assets' : ''}`}>Utilization</Link>
            </div>
            { params.type !== 'aircraft' ?
                <div className='tech-nav-link'>
                    <Link activeClassName='active' to={`/technical-specification/${params.type}/${params.aircraft_slug}/llps${location?.search.includes('archived-assets') ? '?archived-assets' : ''}`}>LLP(s)</Link>
                </div>:null
            }
            { !['fan', 'hpc', 'hpt', 'lpt'].includes(params.type) && checkPermission('technical_specs','shop_visit','R') ?
                <div className='tech-nav-link'>
                    <Link activeClassName='active' to={`/technical-specification/${params.type}/${params.aircraft_slug}/shop-visits${location?.search.includes('archived-assets') ? '?archived-assets' : ''}`}>Shop Visit(s)</Link>
                </div>:null
            }
            
            { params.type === 'aircraft' ?
                <>
                    <div className='tech-nav-link'>
                        <Link activeClassName='active' to={`/technical-specification/${params.type}/${params.aircraft_slug}/avionics${location?.search.includes('archived-assets') ? '?archived-assets' : ''}`}>Avionics</Link>
                    </div>
                    <div className='tech-nav-link'>
                        <Link activeClassName='active' to={`/technical-specification/${params.type}/${params.aircraft_slug}/interior${location?.search.includes('archived-assets') ? '?archived-assets' : ''}`}>Interior</Link>
                    </div>
                    <div className='tech-nav-link'>
                        <Link activeClassName='active' to={`/technical-specification/${params.type}/${params.aircraft_slug}/stc-mods${location?.search.includes('archived-assets') ? '?archived-assets' : ''}`}>STCs & Mods</Link>
                    </div>
                </>:null
            }
            {!['fan', 'hpc', 'hpt', 'lpt'].includes(params.type) ?
                <div className='tech-nav-link'>
                    <Link activeClassName='active' to={`/technical-specification/${params.type}/${params.aircraft_slug}/incident-aog${location?.search.includes('archived-assets') ? '?archived-assets' : ''}`}>Incident & AOG</Link>
                </div>:null
            }
            
            <div className='tech-nav-link'>
                <Link activeClassName='active' to={`/technical-specification/${params.type}/${params.aircraft_slug}/additional-remarks${location?.search.includes('archived-assets') ? '?archived-assets' : ''}`}>Additional Remarks</Link>
            </div>

            {!['fan', 'hpc', 'hpt', 'lpt'].includes(params.type) ? 
                <>
                    <div className='tech-nav-link'>
                        <Link activeClassName='active' to={`/technical-specification/${params.type}/${params.aircraft_slug}/movement-tracker${location?.search.includes('archived-assets') ? '?archived-assets' : ''}`}>Movement Tracker</Link>
                    </div>
                </>:null
            }
        </div>
    )
}
export default withRouter(TechSpecsNav)