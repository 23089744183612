import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, Divider, CircularProgress, Chip } from '@material-ui/core';
import { Pagination, STableLoader, FilterComponent, PageLoader, EmptyCollection, DeletePopUp, GoogleLocationField, RevisionHistory } from '../../shared_elements';
import { TechSpecsNav } from '../../elements'
import { UtilizationInfo, AssetInfo } from '../components'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { globalGetService, globalPutService, globalDeleteService, globalExportService, globalPostService } from '../../../globalServices';
import { checkApiStatus, checkPermission, getLocalStorageInfo } from '../../utils_v2';
import { downloadFileType } from '../../../utils';
import { Flag, GetApp, InfoOutlined } from "@material-ui/icons";
import ExportMenu from "../../shared_elements/ExportMenu";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { getPermissionKey } from "..";
import { assetTypeValues, backendDateFormat, displayDateTimeFormat, fieldDateFormat } from "../../../constants";
import moment from "moment";
import { trackActivity } from "../../../utils/mixpanel";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import { regexConstants } from "../../../constants/regEx";
import EditIcon from '@material-ui/icons/Edit';
import AddUtilization from "../components/AddUtilization";
import { DropzoneArea } from "material-ui-dropzone";
import { Alert, Autocomplete } from "@material-ui/lab";
import Axios from "axios";
import { jetStreamInstance } from "../../../shared/components";

const utilizationObj = {
    name: '',
    asset_id: '',
    asset_type: '',
    utilization_date: '',
    utilization_start_date: '',
    utilization_type: '',
    tsn: '',
    csn: '',
    asset_location: '',
    serviceability: '',
    remarks: ''
}
const attachmentObj = {
    files: [],
}
const Attachmetns = ({ params, item, getResponseBack }) => {
    const [modal, setModal] = useState(false);
    const [deleteAttachment, setDeleteAttachment] = useState({ modal: false, data: null });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [circularLoader, setCircularloader] = useState(false)

    const onDeleteAttachment = () => {
        setCircularloader(true);
        globalDeleteService(`contracts/attachments/${deleteAttachment.data}`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setDeleteAttachment({ modal: false, data: null });
                    getResponseBack({}, 'pageLoader');
                    enqueueSnackbar('Attachment Removed successfully', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                }
                setCircularloader(false);
            })
    }
     const downloadFile = (data) => {
        Axios.get(data.url,{timeout: 600000, responseType : 'blob'})
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',data.name);
            document.body.appendChild(link);
            link.click();
          })
      }
    return (
        <>
            {item?.attachments?.length ? <Tooltip arrow title="View Attachments"><span onClick={() => setModal(true)} style={{ cursor: "pointer" }} className="flex-centered"><AttachFileIcon color="primary" fontSize="small" />({item.attachments.length})</span></Tooltip> : null}
            {modal ?
                <Dialog
                    open={modal}
                    onClose={() => setModal(false)}
                    className='console-management-modal'
                    maxWidth="md"
                >
                    <DialogTitle id="scroll-dialog-title"> Attachment(s) </DialogTitle>
                    <DialogContent dividers={true}>
                        <div style={{ width: '520px' }}>
                            {item.attachments.map((attachment) =>
                                <>
                                    <Grid container spacing={1}>
                                        <Grid item md={10}>
                                            <p style={{ fontSize: "small", margin: "3px 0 5px 0" }}>{attachment.name}</p>
                                        </Grid>
                                        <Grid item md={2}>
                                            <ul className="list-inline" style={{ float: 'right' }}>
                                                <Tooltip placement="bottom-end" title='Download Attachment' arrow>
                                                    <li className="list-inline-item">
                                                        <GetApp style={{ marginTop: "5px", cursor: "pointer" }} fontSize="small" color="primary" onClick={() => downloadFile(attachment)} />
                                                    </li>
                                                </Tooltip>
                                                <Tooltip placement="bottom-end" title='Delete Attachment' arrow>
                                                    <li className="list-inline-item">
                                                        {checkPermission('technical_specs', getPermissionKey(params.type), 'U') && item.custom_utilization === true ?
                                                            <DeleteOutlineIcon style={{ marginTop: "5px", cursor: "pointer" }} onClick={() => { setDeleteAttachment({ modal: true, data: attachment.uuid }) }} color="secondary" fontSize="small" /> : null
                                                            }
                                                    </li>
                                                </Tooltip>
                                            </ul>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </>
                            )}
                            {!item?.attachments?.length ? <div style={{ textAlign: 'center', border: 'dotted', color: '#d2e1e7', padding: '50px' }}><EmptyCollection title={<h4 style={{ color: '#a0bdc8' }}>No Attachment found</h4>} /></div> : null}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setModal(false)} color="primary" size="small" variant="outlined">Close</Button>
                    </DialogActions>
                </Dialog> : null
            }
            {deleteAttachment.modal ?
                <DeletePopUp
                    modal={deleteAttachment.modal}
                    title='Delete Attachment'
                    content={`Are you sure, you want to Delete Attachment?`}
                    toggleModalFn={() => setDeleteAttachment({ modal: false, data: null })}
                    deleteRecordFn={onDeleteAttachment}
                    circularloader={circularLoader}
                /> : null
            }

        </>
    )

}
const AddAttachments = ({ assetInfoData, params, item, getResponseBack }) => {
    const [attachment, setAttachment] = useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState()
    const [files, setFiles] = useState([]);

    const addAttachment = () => {
        let formData = new FormData();
        if (files.length) {
            files.map(item => {
                formData.append('files', item)
            })
        }
        formData.append('asset_type', assetInfoData?.asset_type.type);
        formData.append('asset_slug', params?.aircraft_slug);
        formData.append('model_type', '1');
        formData.append('object_id', item?.id)
        setLoading(true)
        globalPostService(`contracts/attachments/`, formData)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                    trackActivity('Item Added', { page_title: 'Utilization', asset_type: `${params?.type}`, asset_name: `${assetInfoData?.serial_number}`, event_type: 'Add Attacment', event_desc: 'Added custom utiliztion attachment from utiliztion form' });
                    setAttachment(false);
                    getResponseBack()
                }
                setLoading(false);
            })
    }
    return (
        <>
          {checkPermission('technical_specs', getPermissionKey(params.type), 'U') && item.custom_utilization === true ?
            <Tooltip placement='bottom-start' arrow title={"Add Attachment"}><span onClick={() => item.attachments.length !== 5 ? setAttachment(true) : null} style={{ cursor: "pointer" }} className="flex-centered"><AttachFileIcon disabled color={item.attachments.length !== 5 ? "primary" : 'disabled'} fontSize="small" /></span></Tooltip>:null}
            {attachment ?
                <Dialog
                    open={attachment}
                    onClose={() => setAttachment(false)}
                    className='console-management-modal'
                    maxWidth="md"
                >
                    <DialogTitle id="scroll-dialog-title">
                        Add Attachment
                    </DialogTitle>
                    <DialogContent dividers={true} style={{ width: '600px' }}>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <div>
                                    <DropzoneArea
                                        acceptedFiles={['.pdf', '.docx', '.csv', '.xls', '.xlsx', '.jpg', '.jpeg', '.png']}
                                        filesLimit={5}
                                        maxFileSize={104857600}
                                        showPreviewsInDropzone={false}
                                        useChipsForPreview={true}
                                        showPreviews={true}
                                        dropzoneText={<p style={{ fontSize: '13px' }}>Drag & Drop Documents<br /> OR <br /> Click Here<br /> </p>}
                                        dropzoneClass="drag-drop-cnt"
                                        showAlerts={['info', 'error']}
                                        alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                                        clearOnUnmount={true}
                                        onChange={(files) => setFiles(files)}
                                        multiple
                                    />
                                </div>
                                <span style={{ fontSize: '11px' }} className='files-upload-limit' > Note: Only pdf, docx, csv, xls, xlsx, jpg, jpeg and png files are supported (Max Upto 5 Files )</span>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={isLoading} onClick={() => setAttachment(false)} color='primary' size='small' variant='outlined'>Cancel</Button>
                        <Button disabled={isLoading || !files.length} onClick={addAttachment} color='primary' size='small' variant='contained'>
                            {isLoading ? <CircularProgress size={24} /> : 'Save'}
                        </Button>
                    </DialogActions>
                </Dialog> : null}
        </>

    )
}
const UtilizationHd = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell align="">Action(s)</TableCell>
                <TableCell style={{ width: '157px' }} >Period</TableCell>
                <TableCell style={{ width: '157px' }}>Hours</TableCell>
                <TableCell style={{ width: '157px' }}>Cycles</TableCell>
                <TableCell style={{ width: '157px' }}>TSN(Hrs)</TableCell>
                <TableCell style={{ width: '157px' }}>CSN(Cycles)</TableCell>
                <TableCell style={{ width: '157px' }}>Utilization Type</TableCell>
                <TableCell style={{ width: '157px' }}>Location</TableCell>
                <TableCell style={{ width: '157px' }}>Serviceability</TableCell>
                <TableCell >Remarks</TableCell>
            </TableRow>
        </TableHead>
    )
}
const UtilizationList = ({ item, onDelete, params, onEdit, assetInfoData, getResponseBack,id }) => {
    const [showMore, setShowMore] = useState({});
    const toggleDescription = (field) => {
        setShowMore({
            ...showMore,
            [field]: !showMore[field],
        });
    };
    const calculateWidth = (minWidth, maxWidth, content, minCount, maxCount) => {
        let width = 130;
        if (content?.length > minCount && content?.length <= maxCount) {
            width = Math.max(content?.length * 10, minWidth);
        } else if (content?.length > maxCount) {
            width = maxWidth;
        }
        return `${Math.min(width, maxWidth)}px`;
    };

    return (
        <TableRow style={{ backgroundColor: item.custom_utilization === true ? '' : '' }}>
             <TableCell align="">
                <div style={{ minWidth: '130px' }}>
                    <ul className="list-inline">
                        {checkPermission('technical_specs', getPermissionKey(params.type), 'U') && item.custom_utilization === true ?
                            <li className="list-inline-item hover-inline">
                                <Tooltip><AddAttachments getResponseBack={getResponseBack} params={params} assetInfoData={assetInfoData} item={item} /></Tooltip>
                            </li> : null
                        }
                        {checkPermission('technical_specs', getPermissionKey(params.type), 'U') && item.custom_utilization === true ?
                            <li className="list-inline-item hover-inline" >
                                <Tooltip onClick={() => onEdit()} title='Edit' arrow><EditIcon color="primary" fontSize="small" /></Tooltip>
                            </li> : null
                        }
                        {checkPermission('technical_specs', getPermissionKey(params.type), 'U') && item.custom_utilization === true ?
                            <li className="list-inline-item hover-inline">
                                <Tooltip onClick={() => { onDelete() }} title='Delete' arrow><DeleteOutlineIcon color="secondary" fontSize="small" /></Tooltip>
                            </li> : null
                        }
                        {item.custom_utilization === true ?
                            <li className='list-inline-item'>
                                <RevisionHistory id={id} assetInfoData={assetInfoData} isExp={checkPermission('technical_specs', getPermissionKey(params.type), 'EXP')} params={{type: 'utilization', aircraft_slug: 'ZjLWwv'}} buttonType={true} url={`audit/console/amutilization/?action=0,1&object_id=${id}`} />
                            </li>:null
                        }
                    </ul>
                </div>
            </TableCell>
            <TableCell >
                <div className="flex-centered" style={{ minWidth: '280px' }}>
                    {item.utilization_start_date ? moment(item.utilization_start_date).format(displayDateTimeFormat) : moment(item.month).format(displayDateTimeFormat)} to {moment(item.utilization_date).format(displayDateTimeFormat) || '--'}
                    <Attachmetns params={params} getResponseBack={getResponseBack} item={item} />
                </div>
                {item.custom_utilization === true ? <Chip label={'Custom Utilization'} color="" size="small" style={{ fontSize: '12px' }} /> : null}
            </TableCell>
            <TableCell> <div style={{ width: calculateWidth(0, 130, item?.hours || '--', 0, 0) }}>{item?.hours || '--'}</div></TableCell>
            <TableCell ><div style={{ width: calculateWidth(0, 130, item?.cycles || '--', 0, 0) }}>{item?.cycles || '--'}</div></TableCell>
            <TableCell ><div style={{ width: calculateWidth(0, 130, item?.tsn || '--', 8, 10) }}>{(item?.tsn).toFixed(2) || '--'}</div></TableCell>
            <TableCell ><div style={{ width: calculateWidth(0, 130, item?.csn || '--', 8, 10) }}>{(item?.csn).toFixed(2) || '--'}</div></TableCell>
            <TableCell><div style={{ width: calculateWidth(120, 180, item?.utilization_type || '--', 8, 15) }}>{item.utilization_type}</div></TableCell>
            <TableCell ><div style={{ width: calculateWidth(100, 350, item?.asset_location || '--', 0, 30) }}>{item?.asset_location || '--'}</div></TableCell>
            <TableCell><div style={{ width: calculateWidth(0, 180, item?.serviceability || '--', 0, 0) }}>{item?.serviceability || '--'}</div></TableCell>
            <TableCell>
                {item?.remarks && item?.remarks?.length > 30 ? (
                    <div style={{ width: calculateWidth(200, 350, item?.remarks, 30, '') }} >
                        {showMore[item?.field] && showMore[item?.field] ? (
                            <span> {item?.remarks}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  >  less </a>
                            </span>
                        ) : (
                            <span> {item.remarks.substring(0, 25)}
                                <a href='#' style={{ color: '#0E7FE1', fontSize: '12px' }} onClick={(e) => { e.preventDefault(); toggleDescription(item.field); }}  > ..more </a>
                            </span>
                        )}
                    </div>
                ) :
                    <div style={{ width: calculateWidth('150', '195', item.remarks, '1', '25') }}>{item.remarks || '--'}</div>
                }

            </TableCell>
        </TableRow>
    );
}
const AddEditUtilization = ({ addEditUtilization, toggleModalFn, getResponseBack, params, assetInfoData, item, utilizationsInfo }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [utilizationsData, setUtilizationsData] = useState(addEditUtilization.data);
    const [error, setError] = useState({});
    const onAddEditUtilization = () => {
        let dom = params.type === 'engine' || params.type === 'fan' || params.type === 'hpt' || params.type === 'lpt' || params.type === 'hpc' ? assetInfoData?.manufacturing_date : assetInfoData?.date_of_manufacture
        let validationInputs = {};
        validationInputs = {
            ...validationInputs,
            utilization_start_date: utilizationsData.utilization_start_date ? moment(utilizationsData.utilization_start_date).isValid() && moment(utilizationsData.utilization_start_date).isSameOrBefore(moment(), 'day') ? (params.type !== 'lg' ? moment(utilizationsData.utilization_start_date).isSameOrAfter(dom, 'day') : moment(utilizationsData.utilization_start_date).isSameOrAfter(moment().subtract(25, 'years'))) ? '' : "Start Date can't be less than Manufacturing Date" : "Start date can't be future date" : 'Please enter valid Start Date',
            utilization_date: utilizationsData.utilization_date ? moment(utilizationsData.utilization_date).isValid() && moment(utilizationsData.utilization_date).isSameOrBefore(moment(), 'day') ? moment(utilizationsData.utilization_date).isSameOrAfter(utilizationsData.utilization_start_date, 'day') ? '' : "End Date can't be less than Start Date" : "End Date can't be future date" : 'Please enter valid End Date',
            tsn: utilizationsData.tsn ? utilizationsData?.tsn.toString()?.trim()?.length ? '' : 'Please enter TSN' : 'Please enter TSN',
            csn: utilizationsData.csn ? utilizationsData?.csn.toString()?.trim()?.length ? '' : 'Please enter CSN' : 'Please enter CSN',
            utilization_type: utilizationsData.utilization_type?.trim()?.length ? '' : jetStreamInstance?'Please select Utilization Type':'Please enter Utilization Type',
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            let payload = {
                ...utilizationsData,
                asset_location: utilizationsData.asset_location || '',
                serviceability: utilizationsData.serviceability || '',
                remarks: utilizationsData.remarks ? utilizationsData.remarks : '',
                asset_id: assetInfoData.id,
                asset_type: assetInfoData.asset_type.type,
            };
            setLoading(true)
            globalPutService(`/console/${params.type}/${params.aircraft_slug}/custom-utilization/${utilizationsData.id}/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                        trackActivity('Item Edited', { page_title: 'custom utilization', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Custom utlization Info Edited', event_desc: 'Edited custom utlization data from Edit custom Utlization form' });
                        toggleModalFn(); getResponseBack();
                    }
                    setLoading(false)
                })
        }
        else {
            setError(validationInputs)
        }
    }
    const onFieldChange = (key, value) => {
        setUtilizationsData({ ...utilizationsData, [key]: value })
    }

    const onResetError = (key) => setError({ ...error, [key]: '' })

    const assemblytype = (params.type === 'aircraft' ? 'AIRFRAME' : params.type === 'engine' ? 'ENGINE' : params.type === 'propeller' ? 'PROPELLER' : params.type === 'lg' ? 'LANDING GEAR' : params.type === 'apu' ? 'APU' : params.type === 'hpt' ? 'HPT' : params.type === 'lpt' ? 'LPT' : params.type === 'hpc' ? 'HPC' : params.type === 'fan' ? 'FAN' : '')

    return (
        <Dialog
            open={addEditUtilization.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            //maxWidth="md"
            fullScreen
        >
            <DialogTitle id="scroll-dialog-title">
                Edit Utilization
            </DialogTitle>
            <DialogContent>
            <Alert style={{fontSize:'13px'}} severity="info">This option allows the addition of custom entries for TSN and CSN specific to certain events, such as Shop Visits, CTA entry, and lease returns etc.</Alert>
                <Paper style={{ overflow: 'auto' }}>
                    <Table className='mui-table-format' stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Assembly Type</TableCell>
                                <TableCell>Start Date*</TableCell>
                                <TableCell>End Date*</TableCell>
                                <TableCell><div className="flex-centered">TSN*<Tooltip title={'Time Since New'}><InfoOutlined style={{ marginLeft: '5px', fontSize: '1rem', color: '' }}/></Tooltip></div></TableCell>
                                <TableCell><div className="flex-centered">CSN*<Tooltip title={'Cycle Since New'}><InfoOutlined style={{ marginLeft: '5px', fontSize: '1rem', color: '' }}/></Tooltip></div></TableCell>
                                <TableCell>Utilization Type*</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Serviceability</TableCell>
                                <TableCell>Remarks</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow style={{ verticalAlign: 'text-bottom' }}>
                                <TableCell>
                                    <TextField
                                        required
                                        name={`assembly_type`}
                                        value={assemblytype || ''}
                                        fullWidth
                                        margin="none"
                                        inputProps={{ maxLength: 10 }}
                                        onChange={(e) => onFieldChange('assembly_type', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        variant='outlined'
                                        disabled
                                        style={{ width: '200px' }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            required
                                            margin="none"
                                            name={`utilization_start_date}`}
                                            format={fieldDateFormat}
                                            disableFuture
                                            InputLabelProps={{ shrink: true }}
                                            minDate={moment().subtract(25, 'years')}
                                            maxDate={moment()}
                                            value={utilizationsData?.utilization_start_date || null}
                                            onChange={(data, value) => { onFieldChange('utilization_start_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); onResetError('utilization_start_date') }}
                                            inputVariant='outlined'
                                            error={error.utilization_start_date ? true : false}
                                            helperText={error.utilization_start_date || ''}
                                            onFocus={() => onResetError('utilization_start_date')}
                                            style={{ width: '200px' }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </TableCell>
                                <TableCell>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            required
                                            margin="none"
                                            name="utilization_date"
                                            format={fieldDateFormat}
                                            disableFuture
                                            InputLabelProps={{ shrink: true }}
                                            minDate={moment().subtract(25, 'years')}
                                            maxDate={moment()}
                                            value={utilizationsData?.utilization_date || null}
                                            onChange={(data, value) => { onFieldChange('utilization_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); onResetError('utilization_date') }}
                                            inputVariant='outlined'
                                            error={error.utilization_date ? true : false}
                                            helperText={error.utilization_date || ''}
                                            onFocus={() => onResetError('utilization_date')}
                                            style={{ width: '200px' }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        required
                                        name={`tsn`}
                                        value={utilizationsData?.tsn || ''}
                                        fullWidth
                                        margin="none"
                                        inputProps={{ maxLength: 10 }}
                                        onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('tsn', e.target.value) : e.preventDefault()}
                                        InputLabelProps={{ shrink: true }}
                                        variant='outlined'
                                        error={error.tsn ? true : false}
                                        helperText={error.tsn || ''}
                                        onFocus={() => onResetError('tsn')}
                                        style={{ width: '120px' }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        required
                                        name='csn'
                                        value={utilizationsData?.csn || ''}
                                        fullWidth
                                        margin="none"
                                        inputProps={{ maxLength: 10 }}
                                        onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('csn', e.target.value) : e.preventDefault()}
                                        InputLabelProps={{ shrink: true }}
                                        variant='outlined'
                                        error={error.csn ? true : false}
                                        helperText={error.csn || ''}
                                        onFocus={() => onResetError('csn')}
                                        style={{ width: '120px' }}
                                    />
                                </TableCell>
                                <TableCell>
                                {jetStreamInstance ?
                                            (() => {
                                                let MsnOption = [{ label: 'Utilisation Update' }, { label: 'Surveillance Visit' }, { label: 'Maintenance Check' }, { label: 'CTA Delivery' }, { label: 'CTA Return' }];
                                                let otherOption = [{ label: 'Utilisation Update' }, { label: 'Surveillance Visit' }, { label: 'Shop Visit' }, { label: 'CTA Delivery' }, { label: 'CTA Return' }];
                                                let options = (params.type === 'engine' || params.type === 'propeller' || params.type === 'lg'|| params.type === 'apu'  ? otherOption : MsnOption)
                                                return (
                                                    <Autocomplete
                                                        style={{ width: '245px' }}
                                                        options={options}
                                                        getOptionLabel={option => option.label}
                                                        value={options.find(option => option.label === utilizationsData?.utilization_type) || null}
                                                        onChange={(e, value) => onFieldChange('utilization_type', value ? value.label : '')}
                                                        renderInput={params => 
                                                          <TextField 
                                                            placeholder="Select Utilization Type" 
                                                            name={`utilization_type`} 
                                                            required  
                                                            error={error.utilization_type ? true : false}
                                                            helperText={error.utilization_type || ''}
                                                            onFocus={() => onResetError('utilization_type')}{...params} margin='none' fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                                        />)
                                            })()
                                    :
                                    <TextField
                                        required
                                        name='utilization_type'
                                        value={utilizationsData?.utilization_type || ''}
                                        fullWidth
                                        margin="none"
                                        onChange={(e) => onFieldChange('utilization_type', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        variant='outlined'
                                        inputProps={{ maxLength: 150 }}
                                        error={error.utilization_type ? true : false}
                                        helperText={error.utilization_type || ''}
                                        onFocus={() => onResetError('utilization_type')}
                                        style={{ width: '200px' }}
                                    />}
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        name='location'
                                        value={utilizationsData?.asset_location || ''}
                                        fullWidth
                                        margin="none"
                                        inputProps={{ maxLength: 255 }}
                                        onChange={(e) => onFieldChange('asset_location', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        variant='outlined'
                                        style={{ width: '200px' }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        name='serviceability'
                                        value={utilizationsData?.serviceability || ''}
                                        fullWidth
                                        margin="none"
                                        inputProps={{ maxLength: 25 }}
                                        onChange={(e) => onFieldChange('serviceability', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        variant='outlined'
                                        style={{ width: '200px' }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        name='remarks'
                                        value={utilizationsData?.remarks || ''}
                                        fullWidth
                                        margin="none"
                                        onChange={(e) => onFieldChange('remarks', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        variant='outlined'
                                        style={{ width: '200px' }}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button onClick={onAddEditUtilization} disabled={isLoading} color='primary' size='small' variant='contained'>{isLoading ? <CircularProgress size={24} /> : 'Save'}</Button>
            </DialogActions>
        </Dialog>
    )
}
const Utilizations = ({ params }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [assetSkeletonLoader, setAssetSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [utilizationsInfo, setUtilizationsInfo] = useState({ list: [], pagination: {} });
    const [addEditUtilization, setAddEditUtilization] = useState({ modal: false, data: [] });
    const [editUtil, setEditUtil] = useState({ modal: false, data: [] });
    const [deleteUtilization, setDeleteUtilization] = useState({ modal: false, data: null });
    const [assetInfoData, setAssetInfoData] = useState();
    const [circularloader, setCircularloader] = useState(false)

    const filterOption = (
        {
            "startDate": {
                "inputType": 'date',
                "placeholder": 'From Date',
                "title": 'From Date'
            }, "endDate": {
                "inputType": 'date',
                "placeholder": 'To Date',
                "title": 'To Date'
            },
            "custom_util": {
                "inputType": 'dropdown',
                "placeHolder": 'Utilization Type',
                'options': [{ label: 'Custom Utilization', value: '1' }, { label: 'Monthly Utilization', value: '0' }],
                'valueKey': 'value',
                "title": 'Utilization Type',
                "labelKey": 'label',
            }
        })
    useEffect(() => {
        getUtilizations({}, 'skeletonLoader');
        getAssetInfo('setAssetSkeletonLoader');
    }, []);
    const getAssetInfo = (loaderType) => {
        loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/asset_details/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAssetInfoData(response.data.data);
                }
                loaderType === 'setAssetSkeletonLoader' ? setAssetSkeletonLoader(false) : setLoading(false);
            })
    }
    const getUtilizations = (query = {}, loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true)
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/utilization_list/`, query)
            .then(response => {
                if (checkApiStatus(response)) {
                    setUtilizationsInfo(response.data.data)
                    delete query.page
                    delete query.per_page
                    setFilter(query)
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false)
            })
    }

    const onDeleteUtilization = () => {
        setCircularloader(true);
        globalDeleteService(`console/${params.type}/${params.aircraft_slug}/custom-utilization/${deleteUtilization.data.id}/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setDeleteUtilization({ modal: false, data: null });
                    getUtilizations({}, 'pageLoader');
                    enqueueSnackbar('Custom Utilization deleted successfully', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                }
                setCircularloader(false);
            })
    }
    const onExportReport = (extension) => {
        setLoading(true);
        globalExportService(`console/v2/asset/${params.type}/${params.aircraft_slug}/utilization_list/`, { ...filter, download: extension })
            .then(response => {
                downloadFileType(response, `${assetTypeValues[assetInfoData?.asset_type.type].label} ${assetInfoData.serial_number} utilizationlist ${moment().format(fieldDateFormat)}`, extension)
                trackActivity('Export', { page_title: 'Utilization', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Utilization Exported', event_desc: '', file_extension: extension });
                //enqueueSnackbar('Exported Successfully', { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                setLoading(false);
            })
    }

    return (
        <section className="asset-tech-specs">
            <AssetInfo assetInfoData={assetInfoData} skeletonLoader={assetSkeletonLoader} getResponseBack={() => getAssetInfo()} />
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 165}px` }}>
                <TechSpecsNav />
                <div className="tech-specs-content" >
                    <div style={{ padding: '0px 10px' }}>
                        {skeletonLoader ? <STableLoader count={12} /> :
                            <>
                                <div>
                                    <UtilizationInfo assetInfoData={assetInfoData} />
                                </div>
                                <div>
                                    <Paper square style={{ padding: '4px 8px' }}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item md={6}>
                                                <FilterComponent
                                                    filter={filter}
                                                    filterMenu={filterOption}
                                                    getResponseBack={(applyFilter) => getUtilizations({ ...applyFilter, page: 1, per_page: utilizationsInfo?.pagination?.per_page || 20 }, 'pageLoader')}
                                                />
                                            </Grid>
                                                <Grid item md={6}>
                                                    <ul className='list-inline flex-centered' style={{ float: 'right' }}>
                                                       {checkPermission('technical_specs', getPermissionKey(params.type), 'U') ?
                                                        <Tooltip placement='bottom-end' title={getLocalStorageInfo()?.user?.permission?.contracts?'This option enables the option to add Custom Utilization. To add monthly utilization, please add it via the Lease Application.':''}>
                                                            <li className='list-inline-item'>
                                                                <Button onClick={() => setAddEditUtilization({ modal: true, data: [{ ...utilizationObj, asset_id: assetInfoData.id, asset_type: assetInfoData.asset_type.type }] })} variant="contained" color="primary" size="small" >ADD Utilization</Button>
                                                            </li>
                                                        </Tooltip>:null
                                                        }
                                                        {utilizationsInfo.list.length && checkPermission('technical_specs', getPermissionKey(params.type), 'EXP') ?
                                                            <li className='list-inline-item'>
                                                                <ExportMenu
                                                                    files={[{ title: 'Excel', extension: 'xlsx' }, { title: 'Pdf', extension: 'pdf' },]}
                                                                    exportReportFn={(file) => onExportReport(file?.extension)}
                                                                />
                                                            </li> : null
                                                        }
                                                    </ul>
                                                </Grid> 
                                        </Grid>
                                    </Paper>
                                    <Paper style={{ maxHeight: `${window.innerHeight - 400}px`, overflow: 'auto' }} >
                                        <Table className='mui-table-format' stickyHeader>
                                            <UtilizationHd />
                                            <TableBody>
                                                {utilizationsInfo.list.map((item, index) =>
                                                    <UtilizationList
                                                        params={params}
                                                        assetInfoData={assetInfoData}
                                                        key={index}
                                                        item={item}
                                                        id={item.id}
                                                        getResponseBack={() => getUtilizations()}
                                                        onEdit={() => setEditUtil({ modal: true, data: item })}
                                                        onDelete={() => setDeleteUtilization({ modal: true, data: item })}
                                                    />
                                                )}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                    <Paper>
                                        <Pagination
                                            pagination={utilizationsInfo.pagination}
                                            onChangePage={(event, newPage) => getUtilizations({ ...filter, page: newPage + 1, per_page: utilizationsInfo.pagination.per_page }, 'pageLoader')}
                                            onChangeRowsPerPage={(event) => getUtilizations({ ...filter, page: 1, per_page: event.target.value }, 'pageLoader')}
                                        />
                                    </Paper>
                                    <Paper>
                                        {!utilizationsInfo?.list?.length > 0 ? <div style={{ textAlign: 'center' }}><EmptyCollection title='No records found' /></div> : null}
                                    </Paper>

                                </div>
                            </>
                        }
                    </div>
                </div>
                {editUtil.modal ?
                    <AddEditUtilization
                        params={params}
                        addEditUtilization={editUtil}
                        assetInfoData={assetInfoData}
                        utilizationsInfo={utilizationsInfo}
                        toggleModalFn={() => setEditUtil({ modal: false, utilization: [] })}
                        getResponseBack={() => getUtilizations({}, 'pageLoader')}
                    /> : null
                }
                {addEditUtilization.modal ?
                    <AddUtilization
                        assetInfoData={assetInfoData}
                        addEditUtilization={addEditUtilization}
                        utilizationsInfo={utilizationsInfo}
                        toggleModalFn={() => setAddEditUtilization({ modal: false, utilization: [] })}
                        getResponseBack={() => getUtilizations({}, 'pageLoader')}
                    /> : null
                }
                {deleteUtilization.modal ?
                    <DeletePopUp
                        modal={deleteUtilization.modal}
                        title='Delete Utilization'
                        content={`Are you sure, you want to Delete Custom Utilization?`}
                        toggleModalFn={() => setDeleteUtilization({ modal: false, data: null })}
                        deleteRecordFn={onDeleteUtilization}
                        circularloader={circularloader}
                    /> : null
                }

                {isLoading ? <PageLoader /> : null}

            </Paper>
        </section>

    )
}
export default Utilizations;